<template>
  <div>
    <heard-and-footer v-on:getTitleName="getTitleName">
      <title-name slot="title" :my-name="title"
                  :my-background="require('../../assets/image/xiehuifuwu.png')"></title-name>
      <div slot="content">
        <second-navi-bar v-on:getTitle="getTitleName" v-on:setTitle="setTitle" :nav="nav" :now-page="nowPage"
                         :parent1="parent1"></second-navi-bar>
        <router-view v-on:getTitle="getTitle"></router-view>
      </div>
    </heard-and-footer>
  </div>
</template>

<script>
import heardAndFooter from "@/components/public/heardAndFooter";
import TitleName from "@/components/public/TitleName";
import SecondNaviBar from "@/components/public/SecondNaviBar";

export default {
  name: "associationServices",
  components: {
    heardAndFooter,
    TitleName,
    SecondNaviBar,
  },
  data() {
    return {
      title: this.$route.query.name,
      nowPage: this.$route.query.name,
      parent1: '',
      nav: {
        parent: '协会服务',
        children: [
          {index: 0, name: '重要活动', to: '/important?name=重要活动'},
          {index: 1, name: '培训活动', to: '/training?name=培训活动'},
        ]
      },
    }
  },
  methods: {

    getTitleName(val) {
      this.title = val
      this.nowPage = val
    },
    getTitle(val) {
      this.parent1 = this.nowPage
      this.nowPage = val
    },
    setTitle(val) {
      this.nowPage = val.nowPage
      this.parent1 = val.parent1
    }
  }
}
</script>

<style scoped>

</style>